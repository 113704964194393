import React, { useEffect } from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Parser from "utility/Parser"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

if (typeof window !== `undefined`) {
    var search = window.location.search.match(/[?&#]afcode=([^?&#]*)[?&#]?/);
}
if (typeof document !== `undefined`) {
    var cc = document.cookie.match(/[;]?eyecreamAfcode=([^;]*)[;]?/);
}
search = search ? search[1] : null;
cc = cc ? cc[1] : null;

// クエリがなければクッキーを参照
var afcode = search || cc;
if (afcode) {
    var now = new Date();
    now.setHours(now.getHours() + 24);
    document.cookie = "eyecreamAfcode=" + afcode + ";expires=" + now.toUTCString() + ";";
    afcode = "&afcode=" + afcode;
}
afcode = afcode || ''
const cartUrl = process.env.CART_SITE_URL;
const quizUrl = cartUrl + "/hotaru/questions?eyecrm=1" + afcode;

const assetVersion = "?v=2104131700";

// slick setting
const settings = {
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    autoplaySpeed: 10000,
    speed: 2500,
    autoplay: true,
};

const AboutEyecreamPage = () => {
  useEffect(() => {
    setTimeout(() => {
      window.location.href = "/";
    }, "3000");
  })

  return (
      <Layout sectionClasses={"t-eyecream-about"} dataNamespace={"about_eyecream"} quizUrl={quizUrl} isCtaFloat={false}>
          <div className={"t-eyecream-about-end"}>
              こちらの商品は販売終了いたしました。<br />
              ※3秒後に自動的にブランドサイトへ移動します
          </div>
      </Layout>
      // <Layout sectionClasses={"t-eyecream-about"} dataNamespace={"about_eyecream"} quizUrl={quizUrl}>
      //   <SEO title="About Eye Cream" image={"https://hotaru-personalized.com/images/eyecream/pc/ogp.jpg"}/>
      //   <div className="t-eyecream-about">
      //     <div className="t-eyecream-about__op hide">
      //       <p className="t-eyecream-about__op__ttl cen_mid">24 HOUR CARE EYE CREAM</p>
      //       <div className="t-eyecream-about__op__morning">
      //         <div className="t-eyecream-about__op__morning__1 cen_mid">
      //           <picture>
      //             <source media="(max-width:959px)" srcSet={"/images/eyecream/sp/morning_1.png" + assetVersion}/>
      //             <img src={"/images/eyecream/pc/morning_1.png"} alt=""/>
      //           </picture>
      //           <p>MORNING</p>
      //         </div>
      //       </div>
      //       <div className="t-eyecream-about__op__night">
      //         <div className="t-eyecream-about__op__night__1 cen_mid">
      //           <picture>
      //             <source media="(max-width:959px)" srcSet={"/images/eyecream/sp/night_1.png" + assetVersion}/>
      //             <img src={"/images/eyecream/pc/night_1.png"} alt=""/>
      //           </picture>
      //           <p>NIGHT</p>
      //         </div>
      //       </div>
      //       <div className="t-eyecream-about__op__clock cen_mid">
      //         <div>
      //           <img className="t-eyecream-about__op__clock__frame cen_mid"
      //                src={"/images/eyecream/icon/icon_clock_frame.png" + assetVersion}/>
      //           <img className="t-eyecream-about__op__clock__hands cen_mid"
      //                src={"/images/eyecream/icon/icon_clock_hand.png" + assetVersion}/>
      //         </div>
      //         <p>24 HOUR CARE<br/>EYE CREAM</p>
      //       </div>
      //     </div>
      //     <div className="t-eyecream-about__kv" id={"t-eyecream-about__kv"}>
      //       <div className="t-eyecream-about__kv__ttl">
      //         <p className="t-eyecream-about__kv__ttl__hotaru">HOTARU PERSONALIZED</p>
      //         <h1 className="t-eyecream-about__kv__ttl__eyecream">EYE CREAM</h1>
      //       </div>
      //       <Slider {...settings}>
      //         <div className="t-eyecream-about__kv__slide slide__1">
      //           <picture>
      //             <source media="(max-width:959px)" srcSet={"/images/eyecream/sp/fv_1.jpg" + assetVersion}/>
      //             <img src={"/images/eyecream/pc/fv_1.jpg" + assetVersion} alt=""/>
      //           </picture>
      //           <div className="t-eyecream-about__kv__slide__ttl">
      //             <p className="t-eyecream-about__kv__slide__ttl__hotaru">HOTARU PERSONALIZED</p>
      //             <h1 className="t-eyecream-about__kv__slide__ttl__eyecream">EYE CREAM</h1>
      //           </div>
      //           <div className="t-eyecream-about__kv__slide__desc">
      //             <div className="t-eyecream-about__kv__slide__desc__morning">
      //               <p className="t-eyecream-about__kv__slide__desc__ttl">
      //                 <img className="morning" src={"/images/eyecream/icon/icon_morning_white.png" + assetVersion}
      //                      alt=""/><span>MORNING</span>
      //               </p>
      //               <hr/>
      //               <p className="t-eyecream-about__kv__slide__desc__type">AGING</p>
      //               <p className="t-eyecream-about__kv__slide__desc__ingridient">MELISSA EXTRACT</p>
      //             </div>
      //             <div className="t-eyecream-about__kv__slide__desc__night">
      //               <p className="t-eyecream-about__kv__slide__desc__ttl">
      //                 <img className="night" src={"/images/eyecream/icon/icon_night_white.png" + assetVersion} alt=""/>
      //                 <span>NIGHT</span>
      //               </p>
      //               <hr/>
      //               <p className="t-eyecream-about__kv__slide__desc__type txt">PRE-AGING</p>
      //               <p className="t-eyecream-about__kv__slide__desc__ingridient txt">PINEAPPLE CERAMIDE</p>
      //             </div>
      //           </div>
      //         </div>
      //         <div className="t-eyecream-about__kv__slide slide__2">
      //           <picture>
      //             <source media="(max-width:959px)" srcSet={"/images/eyecream/sp/fv_2.jpg" + assetVersion}/>
      //             <img src={"/images/eyecream/pc/fv_2.jpg" + assetVersion} alt=""/>
      //           </picture>
      //           <div className="t-eyecream-about__kv__slide__ttl">
      //             <p className="t-eyecream-about__kv__slide__ttl__hotaru">HOTARU PERSONALIZED</p>
      //             <h1 className="t-eyecream-about__kv__slide__ttl__eyecream">EYE CREAM</h1>
      //           </div>
      //           <div className="t-eyecream-about__kv__slide__desc">
      //             <div className="t-eyecream-about__kv__slide__desc__morning">
      //               <p className="t-eyecream-about__kv__slide__desc__ttl">
      //                 <img className="morning" src={"/images/eyecream/icon/icon_morning_white.png" + assetVersion}
      //                      alt=""/><span>MORNING</span>
      //               </p>
      //               <hr/>
      //               <p className="t-eyecream-about__kv__slide__desc__type">AGING</p>
      //               <p className="t-eyecream-about__kv__slide__desc__ingridient">MELISSA EXTRACT</p>
      //             </div>
      //             <div className="t-eyecream-about__kv__slide__desc__night">
      //               <p className="t-eyecream-about__kv__slide__desc__ttl">
      //                 <img className="night" src={"/images/eyecream/icon/icon_night_white.png" + assetVersion} alt=""/>
      //                 <span>NIGHT</span>
      //               </p>
      //               <hr/>
      //               <p className="t-eyecream-about__kv__slide__desc__type txt">PRE-AGING</p>
      //               <p className="t-eyecream-about__kv__slide__desc__ingridient txt">PINEAPPLE CERAMIDE</p>
      //             </div>
      //           </div>
      //         </div>
      //         <div className="t-eyecream-about__kv__slide slide__3">
      //           <picture>
      //             <source media="(max-width:959px)" srcSet={"/images/eyecream/sp/fv_3.jpg" + assetVersion}/>
      //             <img src={"/images/eyecream/pc/fv_3.jpg" + assetVersion} alt=""/>
      //           </picture>
      //           <div className="t-eyecream-about__kv__slide__ttl">
      //             <p className="t-eyecream-about__kv__slide__ttl__hotaru">HOTARU PERSONALIZED</p>
      //             <h1 className="t-eyecream-about__kv__slide__ttl__eyecream">EYE CREAM</h1>
      //           </div>
      //           <div className="t-eyecream-about__kv__slide__desc">
      //             <div className="t-eyecream-about__kv__slide__desc__morning">
      //               <p className="t-eyecream-about__kv__slide__desc__ttl">
      //                 <img className="morning" src={"/images/eyecream/icon/icon_morning_white.png" + assetVersion}
      //                      alt=""/><span>MORNING</span>
      //               </p>
      //               <hr/>
      //               <p className="t-eyecream-about__kv__slide__desc__type">AGING</p>
      //               <p className="t-eyecream-about__kv__slide__desc__ingridient">MELISSA EXTRACT</p>
      //             </div>
      //             <div className="t-eyecream-about__kv__slide__desc__night">
      //               <p className="t-eyecream-about__kv__slide__desc__ttl">
      //                 <img className="night" src={"/images/eyecream/icon/icon_night_white.png" + assetVersion} alt=""/>
      //                 <span>NIGHT</span>
      //               </p>
      //               <hr/>
      //               <p className="t-eyecream-about__kv__slide__desc__type txt">PRE-AGING</p>
      //               <p className="t-eyecream-about__kv__slide__desc__ingridient txt">PINEAPPLE CERAMIDE</p>
      //             </div>
      //           </div>
      //         </div>
      //       </Slider>
      //     </div>
      //     <div className="t-eyecream-about__concept">
      //       <h2>CONCEPT</h2>
      //       <p className="t-eyecream-about__concept__eye-cream">EYE CREAM</p>
      //       <div className="t-eyecream-about__concept__top">
      //         <div className="t-eyecream-about__concept__top__img">
      //           <picture className="js-smScrollFire js-smScrollFire--fadeUp">
      //             <source media="(max-width:959px)" srcSet={"/images/eyecream/sp/concept_1.png" + assetVersion}/>
      //             <img src={"/images/eyecream/pc/concept_1.png" + assetVersion} alt=""/>
      //           </picture>
      //         </div>
      //         <div className="t-eyecream-about__concept__top__desc">
      //           <p className="t-eyecream-about__concept__top__desc__1"><span>アイケアはエンジングだけじゃない。<br
      //               className="important"/>自分らしさを大切にする全ての人のための<br/>パーソナライズアイクリーム。</span>
      //           </p>
      //           <p className="t-eyecream-about__concept__top__desc__2"><span>目元とは表情がもっとも現れ、人の印象に大きく影響を与<br/>える部位。同時に、肌の中で薄く繊細な部分です。<br/>そこに現れる肌トラブルはストレスによるクマに、<br/>加齢によるシワ・たるみなど人それぞれ。</span>
      //           </p>
      //         </div>
      //       </div>
      //       <div className="t-eyecream-about__concept__bottom">
      //         <div className="t-eyecream-about__concept__bottom__desc">
      //           <p>
      //             そんな目元をケアするHOTARU PERSONALIZED EYE <br/>CREAMは、自分らしさを大切にするあなたにこそ届けた<br/>い、あなたの年齢・肌タイプに合わせてカスタマイズする<br/>アイクリームセット。<br
      //               className="important"/>
      //             <br className="important"/>
      //             オンライン肌診断によってトラブルの原因にアプローチす<br/>る美容成分をブレンドした朝夜2本のアイクリームが、<br/>いつも自信に満ちた目元を作ります。
      //           </p>
      //         </div>
      //         <div className="t-eyecream-about__concept__bottom__img">
      //           <picture>
      //             <source media="(max-width:959px)" srcSet={"/images/eyecream/sp/concept_2.jpg" + assetVersion}/>
      //             <img src={"/images/eyecream/pc/concept_2.jpg" + assetVersion} alt=""/>
      //           </picture>
      //         </div>
      //       </div>
      //       <p className="t-eyecream-about__concept__bg_ttl">PERNSONALIZED EYE CREAME</p>
      //     </div>
      //     <picture className="t-eyecream-about__wd_img js-smScrollFire js-smScrollFire--fadeUp">
      //       <source media="(max-width:959px)" srcSet={"/images/eyecream/sp/concept_3.jpg" + assetVersion}/>
      //       <img src={"/images/eyecream/pc/concept_3.jpg" + assetVersion} alt=""/>
      //     </picture>
      //     <div className="t-eyecream-about__24hour_care">
      //       <h2>24 HOUR CARE</h2>
      //       <p className="t-eyecream-about__24hour_care__cap">肌の活動リズムに合わせて朝用、夜用を「使い分ける」<br/>24時間ケアという新発想薬用アイクリーム
      //       </p>
      //       <picture className="t-eyecream-about__24hour_care__img js-smScrollFire js-smScrollFire--fadeUp">
      //         <source media="(max-width:959px)" srcSet={"/images/eyecream/sp/24hourcare_1.jpg" + assetVersion}/>
      //         <img src={"/images/eyecream/pc/24hourcare_1.jpg" + assetVersion} alt=""/>
      //       </picture>
      //       <p className="t-eyecream-about__24hour_care__desc">肌には活動リズムがあり、夜にはバリア修復、朝には保湿に関連する因子が生成さ<br/>れます。その肌の活動リズムに合わせて、朝・夜のアイクリームを使い分けること<br/>で、常にあなたの目元を最大限にサポートします。さらに朝用はナチュラルメイク<br/>アップ効果で、スキンケアしながらあらゆる目元トラブルをカバー。
      //       </p>
      //       <div className="t-eyecream-about__24hour_care__graph">
      //         <picture className="t-eyecream-about__24hour_care__graph__img">
      //           <source media="(max-width:959px)" srcSet={"/images/eyecream/sp/24hourcare_2.png" + assetVersion}/>
      //           <img src={"/images/eyecream/pc/24hourcare_2.png" + assetVersion} alt=""/>
      //         </picture>
      //       </div>
      //       <div className="t-eyecream-about__24hour_care__morning">
      //         <div className="t-eyecream-about__24hour_care__morning__left">
      //           <div className="t-eyecream-about__24hour_care__morning__block">
      //             <img className="t-eyecream-about__24hour_care__morning__icon"
      //                  src={"/images/eyecream/icon/icon_morning_black_large.png" + assetVersion} alt=""/>
      //             <p className="t-eyecream-about__24hour_care__morning__ttl1">EYE CREAM</p>
      //             <p className="t-eyecream-about__24hour_care__morning__ttl2">MORNING</p>
      //             <p className="t-eyecream-about__24hour_care__morning__desc">肌のタイプに合わせたパーソナライズ処方で、外気のストレスに触れる日中も目元のスキンケア。さらにナチュラルメイクアップ効果で<br/>目元のシワやクマなどの悩みをカバー。
      //             </p>
      //           </div>
      //         </div>
      //         <picture className="t-eyecream-about__24hour_care__morning__img js-smScrollFire js-smScrollFire--fadeUp">
      //           <source media="(max-width:959px)" srcSet={"/images/eyecream/sp/morning_2.jpg" + assetVersion}/>
      //           <img src={"/images/eyecream/pc/morning_2.jpg" + assetVersion} alt=""/>
      //         </picture>
      //         <picture className="t-eyecream-about__24hour_care__morning__item">
      //           <source media="(max-width:959px)" srcSet={"/images/eyecream/sp/morning_1.png" + assetVersion}/>
      //           <img className=" js-smScrollFire js-smScrollFire--fadeUp"
      //                src={"/images/eyecream/pc/morning_1.png" + assetVersion}/>
      //         </picture>
      //       </div>
      //       <div className="t-eyecream-about__24hour_care__night">
      //         <picture className="t-eyecream-about__24hour_care__night__img js-smScrollFire js-smScrollFire--fadeUp">
      //           <source media="(max-width:959px)" srcSet={"/images/eyecream/sp/night_2.jpg" + assetVersion}/>
      //           <img src={"/images/eyecream/pc/night_2.jpg" + assetVersion} alt=""/>
      //         </picture>
      //         <picture className="t-eyecream-about__24hour_care__night__item">
      //           <source media="(max-width:959px)" srcSet={"/images/eyecream/sp/night_1.png" + assetVersion}/>
      //           <img className=" js-smScrollFire js-smScrollFire--fadeUp"
      //                src={"/images/eyecream/pc/night_1.png" + assetVersion}/>
      //         </picture>
      //         <div className="t-eyecream-about__24hour_care__night__right">
      //           <div className="t-eyecream-about__24hour_care__morning__block">
      //             <img className="t-eyecream-about__24hour_care__night__icon"
      //                  src={"/images/eyecream/icon/icon_night_white_large.png" + assetVersion} alt=""/>
      //             <p className="t-eyecream-about__24hour_care__night__ttl1">EYE CREAM</p>
      //             <p className="t-eyecream-about__24hour_care__night__ttl2">NIGHT</p>
      //             <p className="t-eyecream-about__24hour_care__night__desc">日中酷使した目元を、お休み前にいたわり、睡眠中にしっかり修復。体内に備わるバリア修復力をサポートし、ハリのある明るい目元に導きます。</p>
      //           </div>
      //         </div>
      //       </div>
      //     </div>
      //
      //     <picture>
      //       <source media="(max-width:959px)" srcSet={"/images/eyecream/sp/bottom.jpg" + assetVersion}/>
      //       <img className="t-eyecream-about__btm_img" src={"/images/eyecream/pc/bottom.jpg" + assetVersion} alt=""/>
      //     </picture>
      //
      //     <div className="t-eyecream-about__desc">
      //       <img className="t-eyecream-about__desc__offer-img" src={"/images/eyecream/pc/offer_eye.jpg"}/>
      //       <div className="t-eyecream-about__desc__block">
      //         <h2>HOTARU PERSONALIZED<br/>EYE CREAM</h2>
      //         <h3>ホタルパーソナライズドアイクリーム<br/>（朝用・夜用 2本セット）</h3>
      //         <div className="t-eyecream-about__desc__block__label">
      //           <p className="left">４週間に<br/>１回お届け(定期)</p>
      //           <p className="right"><span>初回</span><p><span className="value">4,400</span>円(税込)</p></p>
      //         </div>
      //         <ul>
      //           <li><span>通常価格：</span><span className="value">8,800円</span><span>(税込)</span></li>
      //           <li><span>2回目以降：</span><span className="value">7,920円</span><span>(税込)</span></li>
      //           <li><span>内容量：朝用クリーム(</span><span className="value">8g</span>)<span>・夜用クリーム(</span><span
      //               className="value">8g</span><span>)約1か月分相当</span></li>
      //           <li><span className="free">送料無料</span></li>
      //           <li><span>マイページに記載の次回出荷予定日の11日前（変更締切日）までに解約のお電話がない限り契約が続き、毎月自動で商品をお届けいたします。※土日祝その他休業日は前営業日。不良品や品違いの場合、交換・キャンセル可。解約の電話番号：0570-666-886（月〜金　9:00~19:00  ※ 祝日除く）</span>
      //           </li>
      //           <li><span>ご使用方法は<a
      //               href="https://sparty.my.site.com/spartyshopfaq/s/article/HOTARU-product-7">こちら</a></span></li>
      //         </ul>
      //         <div>
      //           <Link to={quizUrl} className="button">購入する</Link>
      //         </div>
      //       </div>
      //     </div>
      //
      //     {/*
      // <section className="t-about__questions" id="questions">
      //   <img className="t-about__bgImg1" src={"/images/about/color1.png"} alt="" />
      //   <div className="c-grid">
      //     <div className="c-grid__row c-spacer--pb120 c-spacer--pb70-sp">
      //       <div className="c-grid__col js-smScrollFire js-smScrollFire--fadeUp is-active" data-hook="0.9">
      //         <a className="p-button p-button--circle-right p-button--tall" href={quizUrl}>
      //           <span className="p-button__label p-button__label--camel-font-size">
      //             <span className="p-button__label--large u-block-sp c-spacer--pt5-sp">あなたのアイクリームを<br />診断する</span>
      //           </span>
      //         </a>
      //       </div>
      //     </div>
      //   </div>
      // </section>
      // */}
      //
      //   </div>
      // </Layout>
  )
}

export default AboutEyecreamPage
